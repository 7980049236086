
#about {
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    background-color: rgb(2, 0, 87);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-wrapper {
    margin: 0%;
    padding: 0%;
    min-width: 50%;
    width: 60%;
    font-family: PressStart2P;
    font-size: calc(4px + 2vmin);
    line-height: 1.4em;
    text-align: center;
    text-shadow: -1px 1px #e84e40;
    animation: fade-in-grow 2s ease;
}

.about-wrapper a:link {
    color: #e84e40;
}

.about-wrapper a:visited {
    color: #407be8;
}

.about-wrapper h2 {
    margin-top: 6vmin;
    margin-bottom: 0vmin;
}

.about-wrapper p {
    margin-top: 1vmin;
    margin-bottom: 0vmin;
}

.social-icon {
    height: 8vmin;
    margin: .5em .5em;
}

@keyframes fade-in-grow {
    0% {
      opacity: 0;
      transform: scale(.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
}