
#games {
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    background-color: rgb(49, 1, 43);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.games-wrapper {
    min-height: 100vh;
    min-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.steam-game {
    border: none; 
    min-width: 256px;
    width: 80%;
    height: 20vh;
    margin: 1em;
    animation: fade-in-grow 2s ease;
}

.game-list {
    /* top: 10vh; */
    min-height: 0vh;
    list-style: none;
    min-width: 432px;
    width: 60%;
    max-width: 720px;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}
.game-list, li {
    display: block;
}

.game-wrapper {
    /* margin: 1em; */
    width: 100%;
    height: auto;
    min-width: 432px;
    /* padding: 1em 1em; */
    margin-top: 0px;
    margin-bottom: 8%;
}

.game-widget {
    width: 100%;
    border: 1px solid #484e58;
    background-color: #282e39;
    position: relative;
    display: inline-block;
    color: #c9c9c9;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    animation: fade-in-grow 2s ease;
    box-shadow: 0em .1em 2em rgb(0, 0, 0);
}

.game-widget .header {
    display: block;
    padding-top: 1em;
    padding-left: 1em;
}

.game-widget .header .header-name {
    color: white;
    font-family: "Motiva Sans", Sans-serif;
    font-size: 1.4em;
    line-height: 1em;
    margin: 0em;
    padding: 0em;
    display: inline-block;
}

.game-widget .header .header-tail {
    font-style: normal;
    font-family: "Motiva Sans", Sans-serif;
    font-weight: normal;
    font-size: 1.4em;
    line-height: 1em;
    margin: 0em;
    padding: 0em;
    display: inline-block;
    color: #9e9e9e;
}

.game-widget .text_area {
    display: inline-block;
    width: 100%;
    padding-bottom: 1em;
    padding-left: 1em;
    min-width: 8em;
    margin-top: .2em;
    padding: 0;
}

.game-widget .desc {
    font-size: .9em;
    max-height: 6em;
    margin: .5em;
    overflow: hidden;
}

.game-widget .capsule {
    float: left;
    width: 184px;
    height: 69px;
    padding: 0;
    padding-left: 1em;
    padding-bottom: 1em;
    margin: .5em 1em 0em 0em;
}

.game-widget .purchase_area img {
    position: absolute;
    bottom: -1em;
    right: .3em;
    height: 72px;
    min-width: 128px;
    width: 20%;
    height: auto;
}

@keyframes fade-in-grow {
    0% {
      opacity: 0;
      transform: scale(.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
}