

#play {
    background-color: rgb(14, 1, 24);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.gameSelection {
    position: absolute;
    left: 2%;
}

.gameSelection, ul {
    margin-left: 0%;
    padding-left: 0%;
    list-style-type: none;
    display: inline;
}

.gameSelection, a {
    color: #fdd835;
    
}

.gameDesc {
    /* position: absolute; */
    width: 30%;
    /* bottom: 5%; */
    margin-top: 1%;
    text-align: center;
}

.gameDesc h1, p {
    padding: 0px;
    margin: .25em;
}

.gameDesc p {
    font-size: .75em;
}

#gameCanvas {
    image-rendering: pixelated;
    background-image: radial-gradient(
        rgba(45, 182, 45, 0.219), #47474752
    );
    margin: 0;
    padding: 0;
    z-index: 0;
}

#moniter {
    width: 778px;
    height: 628px;

    /* Add monitor */
    border-style: outset;
    border-image-source: url('./assets/Monitor.png');
    border-image-slice: 2% fill;

    /* Center screen */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* Computer background light */
    box-shadow: 0px 0px 256px rgba(172, 219, 201, 0.308);
}

#crt {
    width: 640px;
    height: 480px;
    position: relative;
    
    /* Add brightness around the screen */
    box-shadow: 0px 0px 64px rgb(219, 195, 172);
}

/* 
Credit for the CRT effect goes to aleclownes:
http://aleclownes.com/2017/02/01/crt-display.html
*/
#crt::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        to bottom,
        rgba(18, 16, 16, 0) 50%,
        rgba(0, 0, 0, 0.25) 50%
    );
    background-size: 100% 3px;
    pointer-events: none;
    z-index: 2;
}

#crt::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    pointer-events: none;
    z-index: 2;
    animation: flicker 0.25s infinite;
}

@keyframes flicker {
    0% { opacity: 0.27; }
    10% { opacity: 0.24; }
    20% { opacity: 0.18; }
    30% { opacity: 0.66; }
    40% { opacity: 0.27; }
    50% { opacity: 0.97; }
    60% { opacity: 0.20; }
    70% { opacity: 0.54; }
    80% { opacity: 0.71; }
    90% { opacity: 0.70; }
    100% { opacity: 0.24; }
}