body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #fdd835;
  min-width: 350px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: PressStart2P;
  src: url('./assets/PressStart2P-Regular.ttf');
}

.section-header {
    position: absolute;

    margin-top: 8vmin;
    margin-bottom: 0%;
    top: 0em;
    /* height: 8vh; */
    font-size: calc(6vh);
    font-family: PressStart2P;
    text-decoration-line: underline;
    text-shadow: -2px 2px #e84e40;
}

.down-arrow {
  position: absolute;
  display: inline;
  bottom: 6vmin;
  width: 8%;
  min-width: 64px;
  max-width: 96px;
}

.down-arrow img {
  width: 100%;
  animation: down-arrow-bounce infinite 1s alternate cubic-bezier(0.39, 0.575, 0.565, 1);
}

.flipped {
  transform: scaleY(-1);
  bottom: 6vmin;
}

@keyframes down-arrow-bounce {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -16px);
  }
}