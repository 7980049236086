

#logo {
  text-align: center;
  background-color: black;
  background-image: url(./assets/bg01.png);
  background-repeat: repeat;
  animation: background-anim 5s infinite alternate cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.logo-header {
  /* transition: 2s; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@keyframes background-anim {
  100% {
    background-position: 4px 12px;
  }
}

.main-logo {
  height: 48vmin;
  animation: main-logo-grow infinite 4s alternate cubic-bezier(0.165, 0.84, 0.44, 1);
  
  /* Render crispy img  */
  image-rendering: optimizeSpeed;             /* STOP SMOOTHING                 */
  image-rendering: -moz-crisp-edges;          /* Firefox                        */
  image-rendering: -o-crisp-edges;            /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome/Safari                  */
  image-rendering: pixelated;                 /* Chrome                         */
  image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor;   /* IE8+                           */
}

@keyframes main-logo-grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.main-logo:active {
  animation: main-logo-clicked linear;
}

@keyframes main-logo-clicked {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(.95);
  }
}