
.nav-button {
    position: fixed;
    top: 86vh;
    left: 88%;
    width: 6%;
    min-width: 64px;
    
    left: calc(95% - 64px);
    top: calc(90vh - 64px);
}

.nav-button img {
    width: 100%;
    min-width: 64px;
    height: auto;

    image-rendering: pixelated;
    animation: nav-button-jingle infinite 1s alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* Dont display on mobile/low res */
@media (max-width: 640px) {
  .nav-button {
    display: none;
  }
}

@keyframes nav-button-jingle {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 4px);
  }
}